// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// STYLES
const useStyles = makeStyles({
  title: {
    fontSize: "2rem",
    fontWeight: 600,
    marginBottom: "1rem",
  },
})

// COMPONENT FUNCTION
export const PageTitle = ({ title }) => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT
  return (
    <Typography variant="h1" align="center" className={classes.title}>
      {title}
    </Typography>
  )
}
