// REACT
import React from "react"
// GATSBY
import { graphql } from "gatsby"
// MUI
import { makeStyles } from "@material-ui/core/styles"
import {
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { Link } from "../components/link"
// IMAGES
import PdfIcon from "../assets/pdf-icon.svg"

// STYLES
const useStyles = makeStyles({
  table: {
    maxWidth: 600,
    margin: "0 auto",
  },
  pdf: {
    maxWidth: "32px",
    height: "auto",
  },
})

// COMPONENT FUNCTION
const Brokerage = ({ data }) => {
  // STYLE OBJECT
  const classes = useStyles()

  // DATA
  const listings = data.allMarkdownRemark.edges

  // COMPONENT
  return (
    <Layout title="Brokerage">
      <PageTitle title="BROKERAGE" />
      <TableContainer>
        <Table className={classes.table} aria-label="Brokerage Listings">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Ask</TableCell>
              <TableCell align="center">pdf</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <TableRow key={node.fields.slug}>
                  <TableCell scope="row">
                    <Link to={node.fields.slug}>{title}</Link>
                  </TableCell>
                  <TableCell align="right">{node.frontmatter.price}</TableCell>
                  <TableCell align="center">
                    {node.frontmatter.listingPdf ? (
                      <MuiLink href={node.frontmatter.listingPdf}>
                        <PdfIcon className={classes.pdf} />
                      </MuiLink>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default Brokerage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { documentType: { eq: "Brokerage" }, active: { eq: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            price
            listingPdf
          }
        }
      }
    }
  }
`
